import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const countriesApi = createApi({
  reducerPath: 'countries/api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://countriesnow.space/api/v0.1/' }),
  endpoints: (builder) => ({
    countries: builder.query({
      query: () => 'countries/states',
    }),
    cities: builder.mutation({
        query: ({ country, state }) => ({
          url: 'countries/state/cities',
          method: 'POST',
          body: { country, state },
        }),
    }),
  }),
});

export const { useCountriesQuery, useCitiesMutation } = countriesApi;  // Correct hook name with camelCase
export const { countries, cities } = countriesApi.endpoints;
export default countriesApi;