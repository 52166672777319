import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/index";
import WebApp from "@twa-dev/sdk";

// Tonwallet SDK
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import { LanguageProvider } from "./utils/locales/useLanguageState";

const AppWrapper = () => {
  useEffect(() => {
    const handleTouchMove = (event: any) => {
      if (event.touches.length > 1 || (event.scale && event.scale !== 1)) {
        event.preventDefault();
      }
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  return <App />;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <LanguageProvider>
    <TonConnectUIProvider
    manifestUrl="https://ton-connect.github.io/demo-dapp-with-react-ui/tonconnect-manifest.json"
    uiPreferences={{ theme: THEME.DARK }}
    walletsListConfiguration={{
      includeWallets: [
        {
          appName: "tonwallet",
          name: "TON Wallet",
          imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
          aboutUrl: "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
          universalLink: "https://wallet.ton.org/ton-connect",
          jsBridgeKey: "tonwallet",
          bridgeUrl: "https://bridge.tonapi.io/bridge",
          platforms: ["chrome", "android"]
        },
        {
          appName: "nicegramWallet",
          name: "Nicegram Wallet",
          imageUrl: "https://static.nicegram.app/icon.png",
          aboutUrl: "https://nicegram.app",
          universalLink: "https://nicegram.app/tc",
          deepLink: "nicegram-tc://",
          jsBridgeKey: "nicegramWallet",
          bridgeUrl: "https://bridge.tonapi.io/bridge",
          platforms: ["ios", "android"]
        },
        {
          appName: "binanceTonWeb3Wallet",
          name: "Binance Web3 Wallet",
          imageUrl: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMwIiBoZWlnaHQ9IjMwIiBmaWxsPSIjMEIwRTExIi8+CjxwYXRoIGQ9Ik01IDE1TDcuMjU4MDYgMTIuNzQxOUw5LjUxNjEzIDE1TDcuMjU4MDYgMTcuMjU4MUw1IDE1WiIgZmlsbD0iI0YwQjkwQiIvPgo8cGF0aCBkPSJNOC44NzA5NyAxMS4xMjlMMTUgNUwyMS4xMjkgMTEuMTI5TDE4Ljg3MSAxMy4zODcxTDE1IDkuNTE2MTNMMTEuMTI5IDEzLjM4NzFMOC44NzA5NyAxMS4xMjlaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0xMi43NDE5IDE1TDE1IDEyLjc0MTlMMTcuMjU4MSAxNUwxNSAxNy4yNTgxTDEyLjc0MTkgMTVaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0xMS4xMjkgMTYuNjEyOUw4Ljg3MDk3IDE4Ljg3MUwxNSAyNUwyMS4xMjkgMTguODcxTDE4Ljg3MSAxNi42MTI5TDE1IDIwLjQ4MzlMMTEuMTI5IDE2LjYxMjlaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0yMC40ODM5IDE1TDIyLjc0MTkgMTIuNzQxOUwyNSAxNUwyMi43NDE5IDE3LjI1ODFMMjAuNDgzOSAxNVoiIGZpbGw9IiNGMEI5MEIiLz4KPC9zdmc+Cg==",
          aboutUrl: "https://www.binance.com/en/web3wallet",
          deepLink: "bnc://app.binance.com/cedefi/ton-connect",
          bridgeUrl: "https://bridge.tonapi.io/bridge",
          platforms: ["chrome", "safari", "ios", "android"],
          universalLink: "https://app.binance.com/cedefi/ton-connect"
        },
        {
          appName: "fintopio-tg",
          name: "Fintopio Telegram",
          imageUrl: "https://fintopio.com/favicons/favicon-196x196.png",
          aboutUrl: "https://fintopio.com",
          universalLink: "https://t.me/fintopio?attach=wallet",
          bridgeUrl: "https://wallet-bridge.fintopio.com/bridge",
          platforms: ["ios", "android", "macos", "windows", "linux"]
        },
        {
          appName: "GateWallet",
          name: "GateWallet",
          imageUrl: "https://www.gate.io/images/login/qrcode_center_icon.svg",
          aboutUrl: "https://www.gate.io/",
          bridgeUrl: "https://dapp.gateio.services/tonbridge_api/bridge/v1",
          jsBridgeKey: "gatetonwallet",
          platforms: ["ios", "android", "chrome"],
          universalLink: "https://gateio.onelink.me/DmA6/web3"
        },
        {
          appName: "tokenpocket",
          name: "TokenPocket",
          imageUrl: "https://hk.tpstatic.net/logo/tokenpocket.png",
          aboutUrl: "https://tokenpocket.pro",
          jsBridgeKey: "tokenpocket",
          platforms: ["ios", "android"]
        }
      ]
    }}
    actionsConfiguration={{
      twaReturnUrl: 'https://t.me/DemoDappWithTonConnectBot/demo'
    }}
  >
    <ReduxProvider store={store}>
      <AppWrapper />
    </ReduxProvider>
  </TonConnectUIProvider>
  </LanguageProvider>
);

reportWebVitals();
