// src/components/StateSelector.tsx
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCountriesQuery } from '../../slices/countries/api';
import "./styles.css";

const StateSelector = () => {
  const { register, setValue, formState: { errors } } = useFormContext(); 
  const [states, setStates] = useState([]);
  const country_code = useWatch({ name: 'country_code' });
  
  const { data, isLoading } = useCountriesQuery({}, { skip: !country_code });

  useEffect(() => {
    if (country_code && data) {
      const selectedCountry = data.data.find(
        (country: any) => country.iso2 === country_code
      );
      setStates(selectedCountry?.states || []);
    }
  }, [country_code, data]);

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const stateCode = event.target.value;
    const selectedState = states.find((state:any) => state.state_code === stateCode);
    setValue('state_code', stateCode); 
    //@ts-ignore
    setValue('state_name', selectedState?.name || ''); // Set the state_name in the form context
  };

  if (isLoading) return <p>Loading states...</p>;

  return (
    <div className="form-group mb-3">
      <select
        {...register('state_code', { required: 'State is required' })}
        className={`form-select ${errors.country_code ? 'is-invalid' : ''} select-transparent `}
        style={{
          opacity:`${!country_code ? 0.3 : 1}`,
          fontSize:"16px"
        }}
        id="state"
        onChange={handleStateChange}
        disabled={!country_code}
        aria-label="Select State"
      >
         <option value="">Select State</option>
        {states.map((state: any) => (
          <option key={state.state_code} value={state.state_code}>
            {state.name}
          </option>
        ))}
      </select>
      {errors.state_code && (
        <div className="invalid-feedback">
          {errors.state_code.message as string}
        </div>
      )}
    </div>
  );
};

export default StateSelector;
