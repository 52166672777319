import React from "react";
import IconImage from "../../src/assets/images/icon.svg";
import ErrorBg from "../../src/assets/images/errorbg.png";
import ErrorInner from "../../src/assets/images/errorInner.png";
import Shopcek from "../../src/assets/images/Shopcek.png";

const Error = () => {
  return (
    <div className="container">
      <div
        className="background"
        style={{ backgroundImage: `url(${ErrorBg})` }}
      ></div>
       <div style={overlayStyle}>
        <div style={loaderStyle}>
          <span style={{
            fontSize: '12px',
            fontWeight: 'lighter',
            color: 'white',
            lineHeight: '1',
          }}>Unable to connect to the server. Please try again later.</span>
        </div>
      </div>
    </div>
  );
};


const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  textAlign: 'center',
  zIndex: 1000,
};

const loaderStyle: React.CSSProperties = {
  textAlign: 'center',
  marginBottom: '5px',
};


export default Error;
