import React, { useEffect } from 'react';

interface IOrderLoader {
    loading: boolean;
    status?: string;
    onFinish: () => void;
}

const OrderLoader: React.FC<IOrderLoader> = (props:IOrderLoader) => {

    useEffect(() => {
        console.log('OrderLoader status:', props.status);
        if (!props.loading && props.status === '201') {
            const timeout = setTimeout(() => {
            }, 5000); // 5 seconds to trigger onFinish

            return () => clearTimeout(timeout); // Clean up timeout
        }
    }, [props.loading, props.status, props.onFinish]); // Add props.status to dependencies

    if (!props.loading) return null;

    return (
        <div style={overlayStyle}>
            <div style={loaderStyle}>
                <div>
                    {props.status !== '201' && props.status !== '400' ? (
                        <div>Processing your order...</div>
                    ) : null}
                    {props.status === '201' && (
                        <>
                            <p>Order placed successfully!</p>
                        </>
                    )}
                    {props.status === '400' && (
                        <>
                            <p>An error occurred. Please try again.</p>
                            <button onClick={props.onFinish} style={buttonStyle}>
                                Try Again
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#33b83b',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px',
};

const overlayStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const loaderStyle: React.CSSProperties = {
    textAlign: 'center',
    color: 'white',
    fontSize: '1.5rem',
};

export default OrderLoader;