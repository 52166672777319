import WalletButton from "../../components/buttons/WalletButton";
import AssetsListComp from "../../components/lists/assets/Assets.List.Comp";
import WikzSectionComp from "../../components/sections/Wikz.Section.Comp";
import AirdropInfoBoxComp from "../../components/box/Info.Box.Comp";

export const AirdropPage = () => {   
    return (
        <>
        {/* <AirdropInfoBoxComp/> */}
        <WikzSectionComp/>
        <AssetsListComp/>
        <WalletButton/>
        </>
    )
};

export default AirdropPage