import client from '../../clients/earn'
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosReduxIntegration from '../../axios/axios-redux-integration'
import config from '../../config'
import { setBalance } from './slice'

const notApi = createApi({
    baseQuery: axiosReduxIntegration({ client: client })({
        baseUrl: config.earnUrl + '/not',
    }),
    reducerPath: 'not/api',
    endpoints: (build) => ({
        not: build.query({
            query: () => ({
                url: '/',
                method: 'get',
            }),
            async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
                const { data: not } = await queryFulfilled
                dispatch(setBalance(not))
            },
        }),
    }),
})

export const { useNotQuery } = notApi
export const { not } = notApi.endpoints
export default notApi;