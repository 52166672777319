// src/components/CountrySelector.tsx
import React, { useState } from 'react';
import { useCountriesQuery } from '../../slices/countries/api';

import { useFormContext } from 'react-hook-form';
import "./styles.css";
const CountrySelector = () => {
  const { register, setValue, formState: { errors } } = useFormContext(); 
  const { data, isLoading, isError } = useCountriesQuery({}, {skip:false});
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const countryCode = event.target.value;
    const selectedCountryData = data?.data.find((country:any) => country.iso2 === countryCode);

    setSelectedCountry(countryCode);
    setValue('country_code', countryCode); // Set the country_code in the form context
    setValue('country_name', selectedCountryData?.name || ''); // Set the country_name in the form context
    setValue('state_code', ''); // Reset the state_code when the country changes
    setValue('state_name', ''); // Reset the state_name when the country changes
  };


  if (isLoading) return <p>Loading countries...</p>;
  if (isError) return <p>Error loading countries</p>;

  return (
    <div className="form-group mb-3">
      <select
        {...register('country_code', { required: 'Country is required' })}
        className={`form-select ${errors.country_code ? 'is-invalid' : ''} select-transparent `}
        style={{fontSize:"16px"}}
        id="country"
        onChange={handleCountryChange}
        aria-label="Select Country"
      >
        <option value="" style={{fontSize:"12px"}}>Select Country</option>
        {data?.data.map((country: any) => (
          <option key={country.iso2} value={country.iso2}>
            {country.name}
          </option>
        ))}
      </select>
      {errors.country_code && (
        <div className="invalid-feedback">
          {errors.country_code.message as string}
        </div>
      )}
    </div>
  );
};

export default CountrySelector;