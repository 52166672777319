import React, {useCallback, useMemo, createContext, SetStateAction} from "react";

import LanguageKeysTR, {LanguageProps} from "../../constants/languages/tr";
import LanguageKeysEN from "../../constants/languages/en";
import { getStorageData } from "../localState/usePersistedState";
 
const tr = new LanguageKeysTR();
const en = new LanguageKeysEN();

type State = { language: string; keys: LanguageKeysTR | LanguageKeysEN };

const LanguageStateContext = createContext<State | undefined>(undefined);
const LanguageUpdaterContext = createContext<React.Dispatch<SetStateAction<"en" | "tr">> | undefined>(undefined);

type LanguageProviderProps = {
    children?: React.ReactNode;
};

const LanguageProvider: React.FC<LanguageProviderProps> = ({children}) => {
    const [language, setLanguage] = React.useState<"tr" | "en">( "tr");

    const value = useMemo(() => {
        return {
            language,
            keys: language === "tr" ? tr : en
        };
    }, [language]);

    return (
        <LanguageStateContext.Provider value={value}>
            <LanguageUpdaterContext.Provider value={setLanguage}>
                {children}
            </LanguageUpdaterContext.Provider>
        </LanguageStateContext.Provider>
    );
};

function useLanguageState() {
    const languageState = React.useContext(LanguageStateContext);

    if (languageState === undefined) {
        throw new Error("useLanguageState must be use within a LangProvider");
    }

    return languageState;
}

function useT() {
    const languageState = useLanguageState();
    const t = useCallback((id: LanguageProps, data?: any): string => {
            const textItem: any = (languageState.keys as any)[id];
            const str: string = textItem || id;

            return textItem instanceof Function ? textItem(data) : str;
        },
        [languageState]);
    return t;
}

function useLanguage() {
    getStorageData(`languageState`, false)
    const languageState = useLanguageState();
    // setStorageData(`languageState`, {
    //     app:languageState.language
    // }, false)
    return languageState.language;
}

function useSetLanguage() {
    const setLanguage = React.useContext(LanguageUpdaterContext);
    if (setLanguage === undefined) {
        throw new Error("useSetLang must be used within a LangProvider");

    }
    return setLanguage;
}

type Props = { id: LanguageProps; data?: any };

const T = React.memo(({ id, data }: Props) => {
    const t = useT();
    return <>{t(id, data)}</>;
});

export {LanguageProvider, useSetLanguage, useT, useLanguage, T};
