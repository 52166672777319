import React from 'react';

export type ViewType = 'store' | 'products' | 'productDetails' | 'productPayment' | 'productInvoice';

interface IStoreBreadcrumbComp {
    currentView: ViewType;
    onNavigate: (view: ViewType) => void;
}

const StoreBreadcrumbComp = (props: IStoreBreadcrumbComp) => {
    if (props.currentView === 'store') return null; // Store sayfasında breadcrumb göstermeyelim

    const activeStyle = { color: '#09438c' };

    return (
        <nav aria-label="breadcrumb" className='mt-3' style={{
            fontWeight: '800',
            fontSize: '0.8rem',
        }}>
            <ol className="breadcrumb">
                <li
                    className={`breadcrumb-item ${
                        //@ts-ignore
                        props.currentView === 'store' ? 'active' : ''}`}
                    onClick={() => props.onNavigate('store')}
                    
                    style={{
                        cursor: 'pointer', ...(
                            //@ts-ignore
                            props.currentView === 'store' ? activeStyle : {}
                        ),
                         //@ts-ignore
                        fontWeight: `${props.currentView === 'store' ? '500' : '300'}`
                    }}
                >
                    Store
                </li>
                {props.currentView === 'products' && (
                    <li
                        className={`breadcrumb-item ${props.currentView === 'products' ? 'active' : ''}`}
                        onClick={() => props.onNavigate('products')}
                        style={{ 
                            cursor: 'pointer', ...(props.currentView === 'products' ? activeStyle : {}),
                        }}
                    >
                        Products
                    </li>
                )}
                {props.currentView === 'productDetails' && (
                    <>
                        <li
                            className={`breadcrumb-item ${
                                //@ts-ignore
                                props.currentView === 'products' ? 'active' : ''}`}
                            onClick={() => props.onNavigate('products')}
                            style={{
                                cursor: 'pointer', ...(
                                    //@ts-ignore
                                    props.currentView === 'products' ? activeStyle : {}),     
                            }}
                        >
                            Products
                        </li>
                        <li className={`breadcrumb-item ${props.currentView === 'productDetails' ? 'active' : ''}`}
                            style={{ 
                                ...(props.currentView === 'productDetails' ? activeStyle : {}),                                
                            }}
                        >
                            Product Details
                        </li>
                    </>
                )}
                {props.currentView === 'productPayment' && (
                    <>

                        <li
                            className={`breadcrumb-item ${
                                //@ts-ignore
                                props.currentView === 'products' ? 'active' : ''}`}
                            onClick={() => props.onNavigate('products')}
                            style={{
                                cursor: 'pointer', ...(
                                    //@ts-ignore
                                    props.currentView === 'products' ? activeStyle : {})
                            }}
                        >
                            Product Details
                        </li>
                        <li className={`breadcrumb-item ${props.currentView === 'productPayment' ? 'active' : ''}`}
                            style={{ ...(props.currentView === 'productPayment' ? activeStyle : {}) }}
                        >
                            Product Payment
                        </li>
                    </>
                )}
            </ol>
        </nav>
    );
};

export default StoreBreadcrumbComp;
