import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaymentState {
    paymentStatus: string;
    productDetails: any;  // Ürün bilgileri için uygun bir tip belirleyebilirsiniz
    paymentDetails: any;  // Ödeme bilgileri için uygun bir tip belirleyebilirsiniz
}

const initialState: PaymentState = {
    paymentStatus: '',
    productDetails: null,
    paymentDetails: null,
};

const paymentSlice = createSlice({
    name: 'payment/app',
    initialState,
    reducers: {
        setPaymentSuccess(state, action: PayloadAction<{ product: any; payment: any }>) {
            state.paymentStatus = '201';
            state.productDetails = action.payload.product;
            state.paymentDetails = action.payload.payment;
        },
        resetPaymentState(state) {
            state.paymentStatus = '';
            state.productDetails = null;
            state.paymentDetails = null;
        }
    },
});

export const { setPaymentSuccess, resetPaymentState } = paymentSlice.actions;
export default paymentSlice.reducer;