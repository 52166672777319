import React, { useState } from "react";
import coinIcon from "../../assets/icons/coin-icon.png"
import BlueTick from "../../assets/images/bluetick.png";
import { Image } from "react-bootstrap";
import { taskSelector } from "../../slices/task/slice";
import { useSelector, useDispatch } from "react-redux";
import { taskClaim } from "../../slices/task/api";
import { AppDispatch } from "../../store";

import { Daily } from "./daily";
import { useT } from "../../utils/locales/useLanguageState";

// Define the Task type
interface Task {
  id: string;
  name: string;
  image: string;
  reward: string;
  status: "go" | "claim";
  completed: boolean;
  claimed: boolean;
}

export const Task = () => {
  const t = useT();
  const tasks = useSelector(taskSelector);
  const dispatch: AppDispatch = useDispatch();

  const [ids, setIds] = useState<any[]>([]);

  return (
    <section className="task-section">
      <Daily/>
      <p className="heading my-3 w-100">Earn More Coins</p>
      <div className="task d-flex flex-column align-items-center">
        {tasks.map(({ task, isClaimed }: any) => (
          <div
            key={task.id}
            onClick={() => {
              setIds([...ids, task.id]);
              setTimeout(async () => {
                await dispatch(taskClaim.initiate({ taskId: task.id }));
                setIds(ids.filter((id) => task.id !== id));
              }, 15000);
              window.open(task.link, "_blank");
            }}
            className="d-flex justify-content-between align-items-center follow-container my-1 p-2"
          >
            <div className="d-flex align-items-center gap-3">
             
              <div>
                <Image
                  src={task.icon}
                  alt=""
                  style={
                    task.title === `${t("Follow")} Shopcek On X`
                      ? {
                          width: "70px",
                          height: "100px",
                          marginLeft: "-5px",
                          marginRight: "-4px",
                        }
                      : {}
                  }
                  className={`follow-images ${
                    task.title === `${t("Follow")} Shopcek On X ` ? "x-image" : ""
                  }`}
                />
              </div>

              <div className=" px-1 ">
                <p 
                  className="m-0 task-heading"
                  style={{
                    fontWeight: 800,
                  }}
                >{task.title}</p>
                <div className="d-flex align-items-center">
                  <img
                    src={coinIcon}
                    alt=""
                    className="logo"
                    style={{
                      width: "16px",
                      marginRight: "0.4rem",
                    }}
                  />
                  <h4 style={{
                     margin: "0 0 -1px 0",
                     alignItems: "center",
                     display: "flex",
                     fontWeight:300
                  }}
                  className="reward">+{task.reward.toLocaleString()}</h4>
                </div>
              </div>
            </div>
            <div className="tick-container">
              {isClaimed ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" zoomAndPan="magnify" viewBox="0 0 384 383.999986" height="24" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="7bb5995c4e"><path d="M 0 11.25 L 0 372.75 C 0 378.964844 5.035156 384 11.25 384 L 372.75 384 C 378.964844 384 384 378.964844 384 372.75 L 384 11.25 C 384 5.035156 378.964844 0 372.75 0 L 11.25 0 C 5.035156 0 0 5.035156 0 11.25 Z M 0 11.25 " clip-rule="nonzero"/></clipPath><clipPath id="616b404935"><path d="M 105 123 L 295 123 L 295 260 L 105 260 Z M 105 123 " clip-rule="nonzero"/></clipPath><clipPath id="dd32863dfc"><path d="M 0 11.25 L 0 372.75 C 0 378.964844 5.035156 384 11.25 384 L 372.75 384 C 378.964844 384 384 378.964844 384 372.75 L 384 11.25 C 384 5.035156 378.964844 0 372.75 0 L 11.25 0 C 5.035156 0 0 5.035156 0 11.25 Z M 0 11.25 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#7bb5995c4e)"><path fill="#7c7e8a" d="M 192 0 C 86.128906 0 0 86.128906 0 192 C 0 297.871094 86.128906 384 192 384 C 297.871094 384 384 297.871094 384 192 C 384 86.128906 297.871094 0 192 0 Z M 192 0 " fill-opacity="1" fill-rule="nonzero"/></g><g clip-path="url(#616b404935)"><g clip-path="url(#dd32863dfc)"><path fill="#ffffff" d="M 289.3125 151.3125 L 185.3125 255.3125 C 182.191406 258.433594 178.097656 260 174 260 C 169.902344 260 165.808594 258.433594 162.6875 255.3125 L 110.6875 203.3125 C 104.433594 197.054688 104.433594 186.945312 110.6875 180.6875 C 116.945312 174.433594 127.054688 174.433594 133.3125 180.6875 L 174 221.375 L 266.6875 128.6875 C 272.945312 122.433594 283.054688 122.433594 289.3125 128.6875 C 295.566406 134.945312 295.566406 145.054688 289.3125 151.3125 Z M 289.3125 151.3125 " fill-opacity="1" fill-rule="nonzero"/></g></g></svg>
              ) : (
                <button
                  disabled={ids.find((id) => task.id === id)}
                  className="claim btn"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "800",
                  }}
                >
                  {ids.find((id) => task.id === id) ? (
                    <div
                      className="spinner-border"
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                      role="status"
                    ></div>
                  ) : (
                    "Go"
                  )}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
