import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import axios from "axios";
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

//Components
import OrderQuantity from "../../helpers/product/orderQuantity";
import CountrySelector from "../../components/selectors/CountrySelector";
import StateSelector from "../../components/selectors/StateSelector";
import CitySelector from "../../components/selectors/CitySelector";

import OrderLoader from "../../components/loadings/orderLoader";

import { useDispatch } from "react-redux";
import { setPaymentSuccess } from "../../slices/payment/slice";
//Interfaces
import { IOrderFormInput } from "../../types/interfaces/forms/IForm";
import { setMenu } from "../../slices/selected-menu/slice";

import { CHAIN, SendTransactionRequest, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";

const ProductPaymentPage = () => {
    const dispatch = useDispatch();

    const purchasedProduct = useSelector((state: RootState) => state.shopPurchase.purchasedProduct);

    const methods = useForm<IOrderFormInput>({
        mode: "onChange", // Değişiklik anında validasyon yapar
    });

    const { register, handleSubmit, formState: { errors }, watch } = methods;

    const [orderStatusLoading, setOrderStatusLoading] = useState(false);
    const [orderStatus, setOrderStatus] = useState('');
    const [isPayNowDisabled, setIsPayNowDisabled] = useState(true); // State for button disable

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("TON"); // Default payment method

    const [tonPrice, setTonPrice] = useState(0); // Price conversion rate for the selected payment method
    const [btcPrice, setBtcPrice] = useState(0); // Price conversion rate for the selected payment method

    const formValues = watch();

    const onSubmit: SubmitHandler<IOrderFormInput> = async (data) => {

        const { name, address1, city, state_code, country_code, zip, email, phone } = data;
        const recipient = { name, address1, city, state_code, country_code, zip };
        const packingSlip = { email, phone, message: 'Custom packing slip', logo_url: 'https://i.picsum.photos/id/817/2000/2000.jpg' };

        const items = [
            {
                variant_id: purchasedProduct.variant_id,  // purchasedProduct'tan variant_id alınıyor
                quantity: purchasedProduct.productQuantity, // purchasedProduct'taki quantity kullanılıyor
                files: [{ type: 'front', url: purchasedProduct.thumbnail_url }] // Dosya olarak da thumbnail_url kullanılıyor
            }
        ];

        console.log('Recipient:', purchasedProduct);

        /*try {
            setOrderStatusLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/store/orders`, {
                recipient,
                items,
                packing_slip: packingSlip
            });

            if (response.status === 201) {
                dispatch(setPaymentSuccess({
                    product: purchasedProduct,
                    payment: {
                        recipient,
                        totalAmount: purchasedProduct.retail_price * purchasedProduct.productQuantity
                    }
                }));
                dispatch(setMenu("productInvoice"));
                setOrderStatus('201');
            }
        } catch (error) {
            console.error('Error creating order', error);
            setOrderStatus('400');
        }*/
    }

    useEffect(() => {
        const allFieldsFilled = Object.values(formValues).every(value => value);
        const hasErrors = Object.keys(errors).length > 0;

        setIsPayNowDisabled(!(allFieldsFilled && !hasErrors));
    }, [formValues, errors]);

    useEffect(() => {
        const fetchPrices = async () => {

            try {
                const tonResponse = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd,usdt');
                const btcResponse = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd,usdt');

                //@ts-ignore
                const tonPrice = tonResponse.data['the-open-network'].usd
                //@ts-ignore
                const btcPrice = btcResponse.data['bitcoin'].usd
                setTonPrice(tonPrice);
                setBtcPrice(btcPrice);
            } catch (error) {
                console.error('Error fetching prices', error);
            }
        };

        fetchPrices();
    }, [selectedPaymentMethod]);

 
    useEffect(() => {
        if (purchasedProduct && tonPrice > 0) {
            const amountInTON = (purchasedProduct.retail_price * purchasedProduct.productQuantity) / tonPrice;
            const amountInNanoTON = Math.floor(amountInTON * 1e9); // nanoTON’a çeviri
            setTx(prevTx => ({
                ...prevTx,
                messages: [
                    {
                        ...prevTx.messages[0],
                        amount: amountInNanoTON.toString(),
                    }
                ],
            }));
        }
    }, [purchasedProduct, tonPrice]);

    const [tx, setTx] = useState<SendTransactionRequest>({
        network: CHAIN.TESTNET,
        validUntil: Math.floor(Date.now() / 1000) + 600,
        messages: [
            {
                address: 'UQDf7tixHu1rDOVV_6DbpoxbIqssZNbmLg-gS63HPuM-_4ep',
                amount: '0', // amount buradan hesaplanarak güncellenecek
                stateInit: 'te6cckEBBAEAOgACATQCAQAAART/APSkE/S88sgLAwBI0wHQ0wMBcbCRW+D6QDBwgBDIywVYzxYh+gLLagHPFsmAQPsAlxCarA==',
                payload: 'te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==',
            },
        ],
    });

    const [tonConnectUi] = useTonConnectUI();

    const wallet = useTonWallet();

    if (!purchasedProduct) {
        return <div>No product selected for payment.</div>;
    }
    


    return (
        <div className="mb-5">
            <OrderLoader loading={orderStatusLoading} status={orderStatus} onFinish={() => setOrderStatusLoading(false)} />
            <h4>Your Order</h4>
            <div className="card mb-4"
                style={{
                    minHeight: '85px',
                    maxHeight: '110px',
                    backgroundColor: '#f4f3f8',
                    color: '#595959',
                    overflow: 'hidden',
                    borderRadius: '10px',
                    padding: '0 0 5px 0',
                }}>
                <div className="row no-gutters align-items-center p-2">
                    <div className="col-3 d-flex align-items-center justify-content-center m-0">
                        <img
                            src={purchasedProduct.thumbnail_url}
                            alt={purchasedProduct.name}
                            className="img-fluid"
                            style={{
                                width: '70px',
                                height: '70px',
                                marginRight: '10px',
                                objectFit: 'contain',
                                objectPosition: 'center',
                            }}
                        />
                    </div>
                    <div className="col-6 d-flex flex-column justify-content-center align-items-start p-0">
                        <span style={{
                            fontSize: '0.9rem',
                            fontWeight: '300',
                            color: '#595959',
                            padding: '0',
                            margin: '0 0 1rem 0',
                        }}>{purchasedProduct.name}</span>
                        <div style={{
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '90px',
                        }}>
                            <OrderQuantity onQuantityChange={() => console.log()} />
                        </div>
                    </div>
                    <div className="col-3 d-flex flex-column justify-content-center align-items-center" style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        height: '80px',
                        fontSize: '1rem',
                        fontWeight: '900',

                    }}>
                        <span className="font-weight-bold">$ {(purchasedProduct?.retail_price * purchasedProduct.productQuantity).toFixed(2)}</span>
                    </div>
                </div>
            </div>

            <div className="card mb-2" style={{ backgroundColor: 'transparent', color: '#fff', border: "none" }}>
                <div className="card-body p-0">
                    <h4>Address</h4>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>

                            {/* Name field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("name", {
                                        required: "İsim zorunludur.",
                                        minLength: {
                                            value: 5,
                                            message: "İsim en az 5 karakter olmalıdır."
                                        },
                                        pattern: {
                                            value: /^[A-Za-zğüşİĞÜŞÇçÖö\s]+$/,
                                            message: "İsim sadece harflerden ve boşluklardan oluşmalıdır."
                                        }
                                    })}
                                    type="text"
                                    className={`form-control ${errors.name ? 'is-invalid' : formValues.name ? 'is-valid' : ''}`}
                                    id="exampleInputName"
                                    placeholder="Full Name"
                                />
                                {errors.name ? (
                                    <div className="invalid-feedback">
                                        {errors.name.message}
                                    </div>
                                ) : formValues.name ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>



                            {/* Email field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("email", {
                                        required: "Email zorunludur.",
                                        minLength: {
                                            value: 10,
                                            message: "Email en az 10 karakter olmalıdır."
                                        },
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: "Geçerli bir email formatı girin."
                                        }
                                    })}
                                    type="text"
                                    className={`form-control ${errors.email ? 'is-invalid' : formValues.email ? 'is-valid' : ''}`}
                                    id="exampleInputEmail1"
                                    placeholder="Email"
                                />
                                {errors.email ? (
                                    <div className="invalid-feedback">
                                        {errors.email.message}
                                    </div>
                                ) : formValues.email ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>
                            {/* Phone field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("phone", {
                                        required: "Telefon zorunludur.",
                                        minLength: {
                                            value: 10,
                                            message: "Telefon numarası en az 10 karakter olmalıdır."
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "Telefon numarası sadece rakamlardan oluşmalıdır."
                                        }
                                    })}
                                    type="text"
                                    className={`form-control ${errors.phone ? 'is-invalid' : formValues.phone ? 'is-valid' : ''}`}
                                    id="exampleInputPhone1"
                                    placeholder="Phone"
                                />
                                {errors.phone ? (
                                    <div className="invalid-feedback">
                                        {errors.phone.message}
                                    </div>
                                ) : formValues.phone ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>
                            <CountrySelector />
                            <StateSelector />
                            <CitySelector />
                            {/* Address field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("address1", {
                                        required: "Adres zorunludur.",
                                        minLength: {
                                            value: 10,
                                            message: "Adres en az 10 karakter olmalıdır."
                                        }
                                    })}
                                    type="text"
                                    className={`form-control ${errors.address1 ? 'is-invalid' : formValues.address1 ? 'is-valid' : ''}`}
                                    id="exampleInputAddress1"
                                    placeholder="Address"
                                />
                                {errors.address1 ? (
                                    <div className="invalid-feedback">
                                        {errors.address1.message}
                                    </div>
                                ) : formValues.address1 ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>


                            {/* Zip field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("zip", {
                                        required: "Posta kodu zorunludur.",
                                        minLength: {
                                            value: 2,
                                            message: "Posta kodu en az 2 karakter olmalıdır."
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Posta kodu en fazla 10 karakter olmalıdır."
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "Posta kodu sadece rakamlardan oluşmalıdır."
                                        }
                                    })}
                                    type="text"
                                    className={`form-control ${errors.zip ? 'is-invalid' : formValues.zip ? 'is-valid' : ''}`}
                                    id="exampleInputZip"
                                    placeholder="Zip"
                                />
                                {errors.zip ? (
                                    <div className="invalid-feedback">
                                        {errors.zip.message}
                                    </div>
                                ) : formValues.zip ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                <h4>Payment Information</h4>
                                <div className="card-body" style={{
                                    padding: '10px 0',
                                }}>
                                    <div className="row">
                                        <div className="col-7">
                                            <span style={{ color: '#b4b2b9' }}>Quantity:</span>
                                        </div>
                                        <div className="col-5" style={{
                                            textAlign: 'end',
                                            color: '#595959',
                                        }}>
                                            <span>x{purchasedProduct.productQuantity}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-7">
                                            <span>Total:</span>
                                        </div>
                                        <div className="col-5" style={{
                                            textAlign: 'end',
                                        }}>
                                            <span>$ {(purchasedProduct?.retail_price * purchasedProduct.productQuantity).toFixed(2)}</span>
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <select
                                            className="form-control"
                                            style={{ backgroundColor: 'transparent', color: '#7c7e8a', border: '1px solid #5271ff', fontSize: '1rem', fontWeight: '300' }}
                                            onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                            value={selectedPaymentMethod}
                                        >
                                            <option value="TON">{((purchasedProduct.retail_price * purchasedProduct.productQuantity) / tonPrice).toString().slice(0, 6)} TON</option>
                                            <option value="BTC">{((purchasedProduct.retail_price * purchasedProduct.productQuantity) / btcPrice).toString().slice(0, 6)} BTC</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="d-flex text-left m-8 justify-content-end">
                                    {wallet ? (
                                        <button
                                        style={{
                                            width: '100px',
                                            backgroundColor: `${isPayNowDisabled ? 'gray' : '#33b83b'}`,
                                            color: '#fff',
                                            fontSize: '1rem',
                                            fontWeight: '800',
                                            marginTop: '10px',
                                        }}
                                        type="submit"
                                        //className={`btn btn-primary ${isPayNowDisabled ? 'disabled' : ''}`}
                                        //disabled={isPayNowDisabled}
                                        onClick={() => tonConnectUi.sendTransaction(tx)}
                                    >Pay Now</button>
                                    ) : <button
                                        style={{
                                            width: '100px',
                                            backgroundColor: `#33b83b`,
                                            color: '#fff',
                                            fontSize: '1rem',
                                            fontWeight: '800',
                                            marginTop: '10px',
                                        }}
                                        className={`btn btn-primary`}
                                        onClick={() => tonConnectUi.openModal()}
                                    >Connect Wallet</button>}

                                    {/* <button
                                        style={{
                                            width: '100px',
                                            backgroundColor: `${isPayNowDisabled ? 'gray' : '#33b83b'}`,
                                            color: '#fff',
                                            fontSize: '1rem',
                                            fontWeight: '800',
                                            marginTop: '10px',
                                        }}
                                        //type="submit"
                                        type="submit"
                                        className={`btn btn-primary ${isPayNowDisabled ? 'disabled' : ''}`}
                                        disabled={isPayNowDisabled}
                                    >Pay Now</button> */}
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    );
};

export default ProductPaymentPage;