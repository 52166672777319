import authApi from "./auth/api";
import cardApi from "./card/api";
import xpApi from "./xp/api";
import stackApi from "./stack/api";
import taskApi from "./task/api";
import dailyApi from "./daily-login/api";
import frensApi from "./frens/api";
import leaderBoardApi from "./leader-board/api";
import dailyCardApi from "./daily-card/api";
import dailyQuestionApi from "./daily-question/api";
import usdtApi from "./usdt/api";
import notApi from "./not/api";
import wikzApi from "./wikz/api";

import shopProductApi from "./shopProduct/api";
import countriesApi from "./countries/api";
import productsVariantApi from "./productsVariant/api";
import shopProductDetailApi from "./shopProductDetail/api";

const apiMiddlewares = [
  authApi.middleware,
  cardApi.middleware,
  xpApi.middleware,
  stackApi.middleware,
  taskApi.middleware,
  dailyApi.middleware,
  frensApi.middleware,
  leaderBoardApi.middleware,
  dailyCardApi.middleware,
  dailyQuestionApi.middleware,
  // UsdtApi.middleware is missing here
  usdtApi.middleware,
  // NotApi.middleware is missing here
  notApi.middleware,
  // WikzApi.middleware is missing here
  wikzApi.middleware,
  // ShopProductApi.middleware is missing here
  shopProductApi.middleware,
  shopProductDetailApi.middleware,
  
  countriesApi.middleware,
  productsVariantApi.middleware,
];

export default apiMiddlewares;
