import React, { useEffect, useState } from "react";
import StoreProductCard from "../../components/cards/store/store.product.card";
import getStore from "../../store/get-store";
import axios from "axios";

interface Product {
    id: number;
    name: string;
    thumbnail_url?: string;  // Resim URL'si opsiyonel olabilir, çünkü JSON'da bulunmuyor
    price?: string;     // Fiyat JSON'da olmadığı için opsiyonel yapıyorum
}

interface IProductsPage {
    id: number;
    onCartClick: (productId:string) => void;
}

const ProductsPage: React.FC<IProductsPage> = (props) => {
    const [products, setProducts] = useState<Product[]>([]);  // Ürünleri tutmak için state

    useEffect(() => {
        const jwt = getStore().getState()["auth/app"].data.jwt;

        const fetchCategories = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_ENDPOINT}/shop-product?shop_category_id=${props?.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                );

                // Gelen veriyi state'e set ediyoruz
                setProducts(response.data);

            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, []);

    return (
        <div className="row">
            {products.map((product) => (
                <StoreProductCard
                    key={product.id}
                    productId={String(product.id)}
                    onCartClick={(productId)=>props.onCartClick(productId)}
                />
            ))}
        </div>
    );
};

export default ProductsPage;
