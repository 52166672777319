// store/viewSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ShopViewState = 'store' | 'products' | 'productDetails' | 'productPayment' | 'productInvoice';

interface ShopViewSliceState {
    currentShopView: ShopViewState;
}

const initialState: ShopViewSliceState = {
    currentShopView: 'store',
};

const shopViewSlice = createSlice({
    name: 'view', // Ensure this matches your store configuration
    initialState,
    reducers: {
        setCurrentShopView(state, action: PayloadAction<ShopViewState>) {
            state.currentShopView = action.payload;
        },
    },
});


export const { setCurrentShopView } = shopViewSlice.actions;
export default shopViewSlice;
