import React, { useState, useEffect } from 'react';
import "./style.css";

interface OrderQuantityProps {
  initialQuantity?: number;
  onQuantityChange: (quantity: number) => void;
}

const OrderQuantity: React.FC<OrderQuantityProps> = ({ initialQuantity = 1, onQuantityChange }) => {
  const [quantity, setQuantity] = useState(initialQuantity);

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => {
        const newQuantity = prevQuantity - 1;
        onQuantityChange(newQuantity);
        return newQuantity;
      });
    }
  };

  const handleIncrease = () => {
    setQuantity(prevQuantity => {
      const newQuantity = prevQuantity + 1;
      onQuantityChange(newQuantity);
      return newQuantity;
    });
  };

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  return (
    <div className="d-flex justify-content-between align-items-center border rounded order-quantity">
      <button
        className="btn btn-outline-secondary"
        onClick={handleDecrease}
        disabled={quantity <= 1}
        style={{
          color: '#7c7e8a',
          fontWeight: '300',
          fontSize: '1.4rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
          flex: '2',
          border:"none"
        }}
      >
        -
      </button>
      <div style={{
         color: '#7c7e8a',
         fontWeight: '800',
         fontSize: '0.8rem',
         flex: '1',
         textAlign: 'center',
      }}>{quantity}</div>
      <button
        className="btn btn-outline-secondary"
        onClick={handleIncrease}
        style={{
          color: '#7c7e8a',
          fontWeight: '300',
          fontSize: '1.2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
          flex: '2',
          border:"none"
        }}
      >
        +
      </button>
    </div>
  );
};

export default OrderQuantity;
