import React, { useEffect } from  'react';
import { useDispatch } from "react-redux";
import { AppDispatch } from '../../../store';
import { fetchShopProductDetail } from '../../../slices/shopProductDetail/api';

interface IStoreProductDetailCard {
    productId: string;
}

const StoreProductDetailCard = (props:IStoreProductDetailCard) => {

    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        console.log("StoreProductDetailCard -> props.productId", props.productId)
        dispatch(fetchShopProductDetail.initiate({id: props.productId})).then((response:any) => {
            console.log("StoreProductDetailCard -> response", response)
        }).catch((error:any) => {
            console.log("StoreProductDetailCard -> error", error)
        })
    }, []);

    useEffect(() => {
    })

    return (
        <></>
    );
};

export default StoreProductDetailCard;