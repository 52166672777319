import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const productsVariantApi = createApi({
    baseQuery: fetchBaseQuery ({
        baseUrl:`${process.env.REACT_APP_API_ENDPOINT}`,
    }),
    reducerPath: "productsVariant/api",
    endpoints: (builder) => ({
        fetchProductsVariant: builder.query<any[], {id: string}>({
            query: ({
                id
            }) => {
                const searchParams = new URLSearchParams();
                if (id) {
                    searchParams.append('id', id);
                }
                return {
                    url: `/products/variant?${searchParams.toString()}`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
            },
        }),
    })
});

export const { useFetchProductsVariantQuery } = productsVariantApi;
export const { fetchProductsVariant } = productsVariantApi.endpoints;
export default productsVariantApi;