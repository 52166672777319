// src/slices/shopPurchase/shopPurchaseSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductModel } from "../../types/models/redux/productModel";

interface PurchaseState {
    purchasedProduct: IProductModel | null;
}

const initialState: PurchaseState = {
    purchasedProduct: null
};

const shopPurchaseSlice = createSlice({
    name: "productPurchase",
    initialState,
    reducers: {
        purchaseProduct(state, action: PayloadAction<IProductModel>) {
            state.purchasedProduct = {
                ...action.payload,
                retail_price: Number(action.payload.retail_price) // Ensure it is a number
            };
        },        
        clearPurchase(state) {
            state.purchasedProduct = null;
        }
    },
});

export const { purchaseProduct, clearPurchase } = shopPurchaseSlice.actions;
export default shopPurchaseSlice;
