import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";


export const shopProductApi = createApi({
    baseQuery: fetchBaseQuery ({
        baseUrl:`${process.env.REACT_APP_API_ENDPOINT}`,
    }),
    reducerPath: "shopProduct/api",
    endpoints: (builder) => ({
        fetchShopProduct: builder.query<any[], {id: string}>({
            query: ({
                id
            }) => {
                const searchParams = new URLSearchParams();
                if (id) {
                    searchParams.append('id', id);
                }
                return {
                    url: `/store/products?${searchParams.toString()}`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
            },
        }),        
    }),
});


export const { useFetchShopProductQuery } = shopProductApi;
export const { fetchShopProduct } = shopProductApi.endpoints;
export default shopProductApi;
