// src/components/CitySelector.tsx
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCitiesMutation } from '../../slices/countries/api'; // Import the mutation for getting cities

import "./styles.css";

const CitySelector = () => {
  const { register, setValue, formState: { errors } } = useFormContext(); 
  const [cities, setCities] = useState([]); // Ensure cities is initialized as an array
  const country_name = useWatch({ name: 'country_name' });
  const state_name = useWatch({ name: 'state_name' });

  // Using the mutation to fetch cities
  const [getCities, { data, isLoading, isError }] = useCitiesMutation();

  useEffect(() => {
    if (country_name && state_name) {
      getCities({ country: country_name, state: state_name });
    } else {
      setCities([]); // Reset cities if country or state is not selected
    }
  }, [country_name, state_name, getCities]);

  useEffect(() => {
    if (data) {
      console.log("Fetched cities data:", data.data); // Log fetched data
      setCities(data.data); // Set the cities in the state
    }
  }, [data]);

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const city = event.target.value;
    setValue('city', city); // Set the city in the form context
  };

  if (isLoading) return <p>Loading cities...</p>;
  if (isError) return <p>Error loading cities</p>;

  return (
    <div className="form-group mb-3">
      <select
        id="citySelect"
        className={`form-select ${errors.country_code ? 'is-invalid' : ''} select-transparent `}
        {...register('city', { required: 'City is required' })}
        style={{
          opacity:`${!state_name ? 0.3 : 1}`,
          fontSize:"16px"
        }}
        onChange={handleCityChange}
        disabled={!state_name}
        aria-label="Select State"
      >
         <option value="">Select City</option>
        {cities.map((city: string) => (
          <option key={city} value={city}>
            {city}
          </option>
        ))}
      </select>
      {errors.city && (
        <div className="invalid-feedback">
          {errors.city.message as string}
        </div>
      )}
    </div>
  );
};


export default CitySelector;
