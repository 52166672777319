import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initialState = {
    data: null as any,
}

const shopProductDetailSlice = createSlice({
    name: "shopProductDetail/app",
    initialState,
    reducers: {
        setDetail(state, { payload }) {
            console.log("shopProductDetailSlice -> payload", payload)
            state.data = payload;
        },
    }
});


export const { setDetail } = shopProductDetailSlice.actions;
export default shopProductDetailSlice.reducer;
export const shopProductDetailSelector = (state: RootState) => state["shopProductDetail/app"];