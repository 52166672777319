import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initialState = {
    data: null as any,
};

const usdtSlice = createSlice({
    name: "usdt/app",
    initialState,
    reducers: {
        setBalance(state, { payload: balance }) {
            state.data = balance;
        },
    }
});

export const { setBalance } = usdtSlice.actions;
export default usdtSlice.reducer;
export const usdtSelector = (state: RootState) => state["usdt/app"];