import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initialState = {
    data: null as any,
}

const wikzSlice = createSlice({
    name: "wikz/app",
    initialState,
    reducers: {
        setBalance(state, { payload: balance }) {
            state.data = balance;
        },
    }
});

export const { setBalance } = wikzSlice.actions;
export default wikzSlice.reducer;
export const wikzSelector = (state: RootState) => state["wikz/app"];