import React from "react";
import { Modal, Button } from "react-bootstrap";

interface IFindMySizeModal {
  show: boolean;
  handleClose: () => void;
  sizeInfo: string | null; // Gönderilecek veri
}

const FindMySizeModalComp = (props:IFindMySizeModal) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Find My Size</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Modal içerisine gönderilen veri */}
        <p>Recommended Size: {props.sizeInfo || "Size data is not available"}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FindMySizeModalComp;
