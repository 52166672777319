import client from "../../clients/earn";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosReduxIntegration from "../../axios/axios-redux-integration";
import config from "../../config"
import { setBalance } from "./slice";

const wikzApi = createApi({
    baseQuery: axiosReduxIntegration({ client: client })({
        baseUrl: config.earnUrl + "/wikz",
    }),
    reducerPath: "wikz/api",
    endpoints: (build) => ({
        wikz: build.query({
            query: () => ({
                url: "/",
                method: "get",
            }),
            async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
                const { data: wikz } = await queryFulfilled;
                dispatch(setBalance(wikz));
            },
        }),
    }),
});

export const { useWikzQuery } = wikzApi;
export const { wikz } = wikzApi.endpoints;
export default wikzApi;