import client from "../../clients/earn";
import { createApi } from "@reduxjs/toolkit/query/react"; // Use the correct import from RTK
import axiosReduxIntegration from "../../axios/axios-redux-integration";
import config from "../../config";
import { setDetail } from "./slice";

// Define the expected response type
interface ProductDetail {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    thumbnail_url: string;
    shop_product_detail: {
        id: number;
        detail: string;
        createdAt: string;
        updatedAt: string;
    };
}

// Create the API slice
const shopProductDetailApi = createApi({
    baseQuery: axiosReduxIntegration({ client: client })({
        baseUrl: config.earnUrl,
    }),
    reducerPath: "shopProductDetail/api",
    endpoints: (build) => ({
        fetchShopProductDetail: build.query<ProductDetail, { id: string }>({
            query: ({ id }) => ({
                url: `/shop-products/${id}/detail`,
                method: "get",
            }),
        
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    // Correctly access the data from queryFulfilled
                    const data  = await queryFulfilled; // Here we destructure 'data'
                    console.log("fetchShopProductDetail onQueryStarted", data);
                    // Check if data is defined before dispatching
                    if (data) {
                        dispatch(setDetail(data));
                    } else {
                        console.error("No data returned for fetchShopProductDetail");
                    }
                } catch (error) {
                    console.error("Failed to fetch product detail:", error);
                }
            },
        }),
    }),
});

// Export the generated hook and endpoint correctly
export const { useFetchShopProductDetailQuery } = shopProductDetailApi;
export const { fetchShopProductDetail } = shopProductDetailApi.endpoints;

// Export the API slice
export default shopProductDetailApi;
