import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";

//redux
import { start, stackClaim } from "../../../slices/api";
import { setIcon } from "../../../slices/selected-icon/slice";
import { cardsSelector } from "../../../slices/card/slice";
import { xpSelector } from "../../../slices/xp/slice";
import { AppDispatch, RootState } from "../../../store";

import {
  open,
  close,
  notificationSelector,
} from "../../../slices/notification/slice";

import { setMenu } from "../../../slices/selected-menu/slice";

//images
import CoinIcon from "../../../assets/icons/coin-icon.png";
import EarningLogo from "../../../assets/images/EarningLogo.png";
import remainTime from "../../../assets/images/remain-time.png";
import Rank from "../../../assets/images/1st.png";

//components
import Product from "./product";
import SlideUpPanel from "../../TaskSlider";

import formatNumber from "../../../helpers/format-number";
import { dailyCardSelector } from "../../../slices/daily-card/slice";
import { stackSelector } from "../../../slices/stack/slice";
import Spinner from "../../spinners/Spinner";


// functions
const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
};

export const Earn = () => {
  const xp = useSelector(xpSelector);

  const cards = useSelector(cardsSelector);
  const selectedIcon = useSelector((state: any) => state.selectedIcon.icon);
  const notification = useSelector(notificationSelector);
  const stack = useSelector(stackSelector);
  const { status: cardStatus } = useSelector(dailyCardSelector);

  const isXpLoading = useSelector(
    (state: RootState) => state["xp/api"].queries["xp({})"].status
  );
  const isCardsLoading = useSelector(
    (state: RootState) => state["card/api"].queries["cards({})"].status
  );


  //render when cards updated
  useEffect(() => {
    handleIconClick(selectedIcon);
  }, [isCardsLoading, isXpLoading]);

  const dispatch = useDispatch<AppDispatch>();
  const [selectedCategory, setSelectedCategory] = useState([] as any[]);

  const handleIconClick = (icon: string) => {
    setSelectedCategory(
      cards.filter((item: any) => {
        return item.info.category === icon;
      })
    );
    dispatch(setIcon(icon));
  };

  const { status, earnedXp } = useSelector(
    (state: RootState) => state["stack/app"]
  );

  useEffect(() => {
    if (!status.isWaiting) {
      document.body.style.backgroundColor = "#FF92FF";
    }
  }, [status.isWaiting]);

  const startSelling = async () => {
    dispatch(start.initiate({}));
  };

  const claimSelling = async () => {
    dispatch(stackClaim.initiate({}));
  };

  return (
    <div>
      <iframe
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="100%"
        height="auto"
        src="https://hubsocio.com"
        style={{
          height: "calc(100vh - 71px)",
          position: "relative",
          zIndex: 9999,
          top: "-70px",
          left: "-16px",
          width: "calc(100% + 32px)",
          overflow: "hidden", // Scrolling'i kapatmak için
        }}
      >
      </iframe>
    </div>

  );
};
