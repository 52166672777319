import React, { useEffect } from "react"
import { useAppSelector } from "../../store"
import InvoiceIcon from "../../assets/icons/invoice-icon.png"
const ProductInvoicePage = () => {
    const payment = useAppSelector((state) => state["payment/app"])

    useEffect(() => {
        console.log("payment", payment)
    }, [payment])

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            {/* Mavi Kutucuk */}
            <div
                className="mb-2 mt-3"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img
                    src={InvoiceIcon}
                    style={{
                        width: '70px',
                        height: '70px',
                        objectFit: 'contain',
                        objectPosition: 'center'
                    }}
                />
            </div>

            {/* Transaction Completed Başlığı */}
            <h2 className="text-center fw-light fs-4">Transaction Completed</h2>

            {/* Your Order Başlığı */}
            <div className="card mt-1 w-100" style={{ backgroundColor: 'transparent', color: '#595959', border: "none" }}>
                <h4>OrderID: Q9185</h4>
                <div className="card-body gap-6">
                    <div className="row">
                        <div className="col-6">
                            <span className="fw-lighter">Date:</span>
                        </div>
                        <div className="d-flex col-6 text-right justify-content-end">
                            <span className="fw-lighter">20.09.2024 / 02.05</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <span className="fw-lighter">Payment Method:</span>
                        </div>
                        <div className="d-flex col-6 text-right justify-content-end">
                            <span className="fw-lighter">TON</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <span className="fw-lighter">TxID:</span>
                        </div>
                        <div className="d-flex col-6 text-right justify-content-end">
                            <span className="fw-lighter">19a8chf98d8.......9d95o65</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            }}>
                <h3 className="p-0 mt-2">Your Order</h3>
            </div>
            {/* Ürün Kartı */}
            <div className="mb-5 w-100">
                {
                     payment?.productDetails?.products?.length != undefined || payment?.productDetails?.products?.length > 1 ?
                        payment?.productDetails?.products.map((productDetails: any, index: any) => (
                            <div
                                className="card mt-1"
                                style={{   backgroundColor: '#f4f3f8', color: '#595959', width: '100%' }}
                            >
                                {/* Birinci Bölüm */}
                                <div className="row no-gutters align-items-center py-2 px-2">
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        {/* Ürün Resmi */}
                                        <img
                                            src={productDetails?.thumbnail_url}
                                            alt="Product"
                                            className="img-fluid"
                                            style={{ width: '100%', height: '75px' }}
                                        />
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        {/* Ürün Başlığı */}
                                        <span className="card-title mb-0 fw-bold">{productDetails?.name}</span>
                                    </div>
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        {/* Ürün Fiyatı */}
                                        <span className="card-text mb-0">$ {productDetails?.retail_price * productDetails?.productQuantity}</span>
                                    </div>
                                </div>

                                {/* Divider */}
                                <hr className="my-2" style={{ borderColor: 'rgba(255, 255, 255, 0.2)' }} />
                                {/* İkinci Bölüm */}
                                <div className="card-body p-2">
                                    {/* Ürün Bilgileri */}
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="fw-lighter" style={{ color: '#b4b2b9' }}>Order Amount:</span>
                                        </div>
                                        <div className="d-flex col-6 text-right justify-content-end">
                                            <span className="fw-lighter">{productDetails?.retail_price * productDetails?.productQuantity} USD</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="fw-lighter">Shipping:</span>
                                        </div>
                                        <div className="d-flex col-6 text-right justify-content-end">
                                            <span className="fw-lighter">0 USD</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="fw-bolder">Total : </span>
                                        </div>
                                        <div className="d-flex col-6 text-right justify-content-end">
                                            <span className="fw-lighter fw-bold">{productDetails?.retail_price * productDetails?.productQuantity} USD</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <div
                            className="card mt-1"
                            style={{ backgroundColor: '#313f54', color: 'white', width: '100%' }}
                        >
                            {/* Birinci Bölüm */}
                            <div className="row no-gutters align-items-center py-2 px-2">
                                <div className="col-3 d-flex align-items-center justify-content-center">
                                    {/* Ürün Resmi */}
                                    <img
                                        src={payment?.productDetails?.thumbnail_url}
                                        alt="Product"
                                        className="img-fluid"
                                        style={{ width: '100%', height: '75px' }}
                                    />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    {/* Ürün Başlığı */}
                                    <span className="card-title mb-0 fw-bold">{payment?.productDetails?.name}</span>
                                </div>
                                <div className="col-3 d-flex align-items-center justify-content-center">
                                    {/* Ürün Fiyatı */}
                                    <span className="card-text mb-0">$ {payment?.productDetails?.retail_price * payment?.productDetails?.productQuantity}</span>
                                </div>
                            </div>

                            {/* Divider */}
                            <hr className="my-2" style={{ borderColor: 'rgba(255, 255, 255, 0.2)' }} />
                            {/* İkinci Bölüm */}
                            <div className="card-body p-2">
                                {/* Ürün Bilgileri */}
                                <div className="row">
                                    <div className="col-6">
                                        <span className="fw-lighter" style={{ color: '#b4b2b9' }}>Order Amount:</span>
                                    </div>
                                    <div className="d-flex col-6 text-right justify-content-end">
                                        <span className="fw-lighter">{payment?.productDetails?.retail_price * payment?.productDetails?.productQuantity} USD</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <span className="fw-lighter">Shipping:</span>
                                    </div>
                                    <div className="d-flex col-6 text-right justify-content-end">
                                        <span className="fw-lighter">0 USD</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <span className="fw-bolder">Total : </span>
                                    </div>
                                    <div className="d-flex col-6 text-right justify-content-end">
                                        <span className="fw-lighter fw-bold">{payment?.productDetails?.retail_price * payment?.productDetails?.productQuantity} USD</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>

    )
}

export default ProductInvoicePage