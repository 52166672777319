import client from "../../clients/earn";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosReduxIntegration from "../../axios/axios-redux-integration";
import config from "../../config";
import { setBalance } from "./slice";

const usdtApi = createApi({
    baseQuery: axiosReduxIntegration({ client: client })({
        baseUrl: config.earnUrl + "/usdt",
    }),
    reducerPath: "usdt/api",
    endpoints: (build) => ({
        usdt: build.query({
            query: () => ({
                url: "/",
                method: "get",
            }),
            async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
                const { data: usdt } = await queryFulfilled;
                dispatch(setBalance(usdt));
            },
        }),
    }),
});

export const { useUsdtQuery } = usdtApi;
export const { usdt } = usdtApi.endpoints;
export default usdtApi;