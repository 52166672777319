import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//import Product from "../../components/earn/mine/product";
import { IProductModel } from "../../types/models/redux/productModel";

interface BasketState {
  products: IProductModel[];
  productTotalQuantity: number;
  productTotalAmount: number;
}

const initialState: BasketState = {
  products: localStorage.getItem("productBasket") ? JSON.parse(localStorage.getItem("productBasket") as string) : [],
  productTotalQuantity: 0,
  productTotalAmount: 0
};

const shopBasketSlice = createSlice({
  name: "productBasket",
  initialState,
  reducers: {
    addProductToBasket(state, action: PayloadAction<IProductModel>) {

       const itemIndex = state.products.findIndex(
        (item) => item.id === action.payload.id && item.color === action.payload.color && item.size === action.payload.size
      );

      if (itemIndex >= 0) {
        // If the product already exists, increase the quantity
        state.products[itemIndex].productQuantity! += action.payload.productQuantity || 1;
      } else {
        // If it's a new product, ensure a default quantity of 1 if not provided
        const tempProduct = { ...action.payload, productQuantity: action.payload.productQuantity || 1 };
        state.products.push(tempProduct);
      }

      // Recalculate the total quantity and total amount
      state.productTotalQuantity = state.products.reduce(
        (total, product) => total + (product.productQuantity || 1),
        0
      );

      state.productTotalAmount = state.products.reduce(
        (total, product) =>
          total + (product.retail_price * (product.productQuantity || 1)),
        0
      );

      // Save the updated basket to localStorage
      localStorage.setItem("productBasket", JSON.stringify(state.products));
    },

    updateProductBasket(state, action: PayloadAction<IProductModel[]>) {
      // Ensure all products have a valid productQuantity, defaulting to 1 if missing
      state.products = action.payload.map(product => ({
        ...product,
        productQuantity: product.productQuantity || 1, // Ensuring quantity is set
        //@ts-ignore
        retail_price: parseFloat(product.retail_price) // Ensure retail_price is a number
      }));

      // Recalculate totals
      state.productTotalQuantity = state.products.reduce(
        (total, product) => total + (product.productQuantity || 1),
        0
      );

      state.productTotalAmount = state.products.reduce(
        (total, product) =>
          total + (product.retail_price * (product.productQuantity || 1)),
        0
      );

      // Save the updated basket to localStorage
      localStorage.setItem("productBasket", JSON.stringify(state.products));
    },


    updateProductQuantity(state, action: PayloadAction<{ id: string; color?: string; size?: string; quantity: number }>) {
      const { id, color, size, quantity } = action.payload;
      const product = state.products.find(p => p.id.toString() === id && p.color === color && p.size === size);
      if (product) {
        product.productQuantity = quantity;

        // Recalculate the total quantity and total amount
        state.productTotalQuantity = state.products.reduce(
          (total, product) => total + (product.productQuantity || 1),
          0
        );
        
        state.productTotalAmount = state.products.reduce(
          (total, product) =>
            total + (product.retail_price * (product.productQuantity || 1)),
          0
        );

        // Save the updated basket to localStorage
        localStorage.setItem("productBasket", JSON.stringify(state.products));
      }
    },

    removeProductFromBasket(state, action: PayloadAction<{ id: string; color?: string; size?: string }>) {
      const { id, color, size } = action.payload;
      state.products = state.products.filter(product => product.id.toString() !== id || product.color !== color || product.size !== size);

      // Recalculate the totals
      state.productTotalQuantity = state.products.reduce(
        (total, product) => total + (product.productQuantity || 1),
        0
      );

      state.productTotalAmount = state.products.reduce(
        (total, product) =>
          total + (product.retail_price * (product.productQuantity || 1)),
        0
      );

      // Save the updated basket to localStorage
      localStorage.setItem("productBasket", JSON.stringify(state.products));
    },
    clearBasket(state) {
      state.products = [];
      state.productTotalQuantity = 0;
      state.productTotalAmount = 0;
      localStorage.removeItem("productBasket");  // Clear from localStorage as well
    }
  },
});



export const {
  addProductToBasket,
  updateProductBasket,
  updateProductQuantity,
  removeProductFromBasket,
  clearBasket
} = shopBasketSlice.actions;
export default shopBasketSlice;
