import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../slices";
import apiMiddlewares from "../slices/middlewares";
import {setupListeners} from "@reduxjs/toolkit/query";
import {useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";


export const store: any = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(...apiMiddlewares) as ReturnType<
      typeof getDefaultMiddleware
    >;
  },
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

setupListeners(store.dispatch)