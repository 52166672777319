import React from 'react';
import './style.css';
import WalletConnectComp from '../wallet/wallet.connect.comp';

const WalletButton: React.FC = () => {
  return (
    <WalletConnectComp />
  );
};

export default WalletButton;
