import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initialState = {
    data: null as any,
};

const notSlice = createSlice({
    name: "not/app",
    initialState,
    reducers: {
        setBalance(state, { payload: balance }) {
            state.data = balance;
        },
    }
});

export const { setBalance } = notSlice.actions;
export default notSlice.reducer;
export const notSelector = (state: RootState) => state["not/app"];