import React from "react";
import WikzLogo from "../assets/logos/wikz-circle-dark-logo.png";
import ErrorBg from "../../src/assets/images/errorbg.png";
import Shopcek from "../../src/assets/images/Shopcek.png";

const Loading = () => {
  return (
    <div className="Loading-container">
      <div
        className="Loading-background"
        style={{ backgroundImage: `url(${ErrorBg})` }}
      ></div>
      <div style={overlayStyle}>
        <div style={loaderStyle}>
          <div className="spinner-border" role="status" style={{
            color: '#5271ff',
            width: '30px',
            height: '30px',
            borderWidth: '1px',
          }}>

          </div>
        </div>
      </div>
    </div>
  );
};


const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  zIndex: 1000,
  marginBottom: '4rem',
};

const loaderStyle: React.CSSProperties = {
  textAlign: 'center',
};

export default Loading;
