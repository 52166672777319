import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initialState = {
    data: null as any,
}

const shopProductSlice = createSlice({
    name: "shopProduct/app",
    initialState,
    reducers: {}
});



export default shopProductSlice.reducer;
export const shopProductSelector = (state: RootState) => state["shopProduct/app"];