export class LanguageKeysTR {
    SpinTheWheel="Çarkı Çevir";
    PleaseWait="Lütfen Bekleyin";
    Hours="Saat";
    Earn="Kazan";
    Frens="Arkadaşlar";
    Wikz="Wikz";
    Tasks="Görevler";
    Airdrops="Airdrop";
    //
    GiveItAWhirl="Dene şansını!";
    DonTForget="Unutma, 8 saat sonra geri gelebilirsin";
    AnotherSpin="başka bir çevrim için.";
    InviteFriends="Arkadaşlarını Davet Et";
    InviteAFriend="Bir arkadaşını davet et";
    GiveAwayWithoutLottery="Çekilişsiz hediye";
    ReferralPrizePool="Referans Ödül Havuzu";
    ForYouAndYourFriend="Senin ve arkadaşın için";
    ListOYourFriends="Arkadaşlarının listesi";
    Copied="Kopyalandı!";
    //
    DailyTasks="Günlük Görevler";
    Claim="Talep Et";
    DailyLogIn="Günlük Giriş";
    CompleteYourDaily="Günlük görevlerini tamamla ve ödüllerini al!";
    Day="Gün";
    EarnMoreCoins="Daha Fazla Coin Kazan";
    Follow="Takip Et";
    Subscribe="Abone Ol";
    Join="Katıl";
    //
    HowToEarn="Nasıl Kazanılır?";
    GetReadyForkWikzCoin="Wikz Coin için hazır ol";
    WikZAppLaunched="WikZ App başlatıldı!";
    NOTWIKZReferralPool="NOT ve WIKZ Referans Havuzu";
    WikZCoinsBigAirdrop="WikZ Coins Büyük Airdrop";
    ExchangeListing="Borsa Listesi";
    Soon="Yakında";
    //
    Vibrate="Titreşim";
    Language="Dil";
    Dashboard="Gösterge Paneli";
    Orders="Siparişler";
    Support="Destek";
    TelegramChannel="Telegram Kanalı";
    //
    YourBalance="Bakiyeniz";
    Loading="Yükleniyor";
    Spinning="Dönüyor";
    Spin="Çevir";
}

export type LanguageProps = keyof LanguageKeysTR;
export default LanguageKeysTR
